$primary:       #651fff !default;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import '@angular/material/theming';

$mat-material-blue: (
  50: #bd9eff,
  100: #ab85ff,
  200: #9a6bff,
  300: #8852ff,
  400: #7738ff,
  500: #651fff,
  600: #5305ff,
  700: #4900eb,
  800: #4100d1,
  900: #3900b8,
  A100: #ceb8ff,
  A200: #e0d1ff,
  A400: #f1ebff,
  A700: #32009e,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$primary-palette: mat-palette($mat-material-blue);
$accent-palette: mat-palette($mat-pink, A200, A100, A400);
$theme: mat-light-theme($primary-palette, $accent-palette);

$mat-primary: map-get($theme, primary);
$accent: map-get($theme, accent);
$warn: map-get($theme, warn);
$foreground: map-get($theme, foreground);
$background: map-get($theme, background);
$config: mat-typography-config();
//$background: map_merge(map-get($theme, background), (background: #fff));
//$theme: map_merge($theme, (background: map_merge(map-get($theme, background), (background: #fff))));

@mixin theme-elevation($zValue) {
  @include mat-elevation($zValue, map-get($mat-primary, text));
}

$font-weight-base: 500;
$font-size-base: 0.875rem;

$link-hover-decoration: none;

$nav-link-padding-y: 1rem;
$nav-link-padding-x: 1.5rem;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-color: $primary;

$btn-font-weight: $font-weight-base;

$modal-header-border-width: 0;
$modal-content-border-width: 0;
$modal-footer-border-width: 0;
$modal-content-color: $dark;

$dropdown-font-size: $font-size-base;
$dropdown-border-radius: $border-radius-lg;
$dropdown-inner-border-radius: $border-radius-lg;
$dropdown-link-color: $dark;
$dropdown-link-hover-color: $primary;
$dropdown-link-hover-bg: rgba($primary, 0.075);
$dropdown-link-active-color: $primary;
$dropdown-link-active-bg: rgba($primary, 0.15);
$dropdown-item-padding-y: 0;
$dropdown-item-padding-x: 1rem;
$dropdown-border-width: 0;

$border-color: rgba(100, 121, 143, 0.1);

