/* You can add global styles to this file, and also import other style files */

@import "./theme/variables.scss";
@import "./theme/saturn_datepicker_theming";
@import "./theme/calendar.scss";

@import "bootstrap/scss/bootstrap";
@import "ngx-bootstrap/datepicker/bs-datepicker.css";

@import url('https://fonts.googleapis.com/css?family=Google+Sans:400,500,700&display=swap');
$font-family-base: 'Google Sans', sans-serif;
$custom-typography: mat-typography-config( $font-family: 'Google Sans, sans-serif',
$headline: mat-typography-level(24px, 32px, 700),
$title: mat-typography-level(20px, 32px, 700),
$body-1: mat-typography-level(14px, 20px, 500));

@include mat-core($custom-typography);
@include mat-ripple-theme($theme);
@include mat-option-theme($theme);
@include mat-optgroup-theme($theme);
@include mat-pseudo-checkbox-theme($theme);
// Provides external CSS classes for each elevation value. Each CSS class is formatted as
// `mat-elevation-z$zValue` where `$zValue` corresponds to the z-space to which the element is
// elevated.
@for $zValue from 0 through 24 {
    .#{$mat-elevation-prefix}#{$zValue} {
        @include theme-elevation($zValue);
    }
}

// Wrapper element that provides the theme background when the user's content isn't
// inside of a `mat-sidenav-container`. Note that we need to exclude the ampersand
// selector in case the mixin is included at the top level.
.mat-app-background#{if(&, ', &.mat-app-background', '')} {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    background-color: mat-color($background, background);
    color: mat-color($foreground, text);
}

// Marker that is used to determine whether the user has added a theme to their page.
@at-root {
    .mat-theme-loaded-marker {
        display: none;
    }
}

@include mat-button-theme($theme);
@include mat-divider-theme($theme);
@include mat-paginator-theme($theme);
@include mat-checkbox-theme($theme);
@include mat-radio-theme($theme);
@include mat-tooltip-theme($theme);
@include mat-toolbar-theme($theme);
@include mat-menu-theme($theme);
@include mat-icon-theme($theme);
@include mat-ripple-theme($theme);
@include mat-grid-list-theme($theme);
@include mat-list-theme($theme);
@include mat-dialog-theme($theme);
@include mat-form-field-theme($theme);
@include mat-autocomplete-theme($theme);
@include mat-stepper-theme($theme);
@include mat-select-theme($theme);
@include mat-snack-bar-theme($theme);
@include mat-progress-bar-theme($theme);
@include mat-progress-spinner-theme($theme);
@include mat-chips-theme($theme);
@include mat-badge-theme($theme);
@include mat-table-theme($theme);
@include mat-sort-theme($theme);
@include mat-card-theme($theme);
@include mat-datepicker-theme($theme);
@include sat-datepicker-theme($theme);
@include mat-tabs-theme($theme);
@include mat-sidenav-theme($theme);
@include mat-expansion-panel-theme($theme);
@include mat-bottom-sheet-theme($theme);
@include mat-slide-toggle-theme($theme);
.mat-button .mat-button-wrapper>*,
.mat-fab .mat-button-wrapper>*,
.mat-flat-button .mat-button-wrapper>*,
.mat-icon-button .mat-button-wrapper>*,
.mat-mini-fab .mat-button-wrapper>*,
.mat-raised-button .mat-button-wrapper>*,
.mat-stroked-button .mat-button-wrapper>*,
.mat-step-icon .mat-icon {
    display: flex;
}

.rounded-75 {
    border-radius: 20px !important;
}

.card-background {
    background-color: mat-color($background, 'card');
}

.mat-paginator.marginless-paginator-range-label {
    .mat-paginator-range-label {
        margin: 0;
    }
}

.cursor {
    cursor: pointer;
}

.text-purple {
    color: #3b5dc4;
}

.btn-shadow {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-bootstrap-btn {
    color: #3b5dc4;
    font-family: Google Sans, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    border-color: #3b5dc4;
}

.mat-bootstrap-btn:hover {
    color: white;
    background: #3b5dc4;
    font-family: Google Sans, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
}

.border-0 {
    border-radius: 0 !important
}

.text-status {
    color: #72bb6f;
}

.text-inactive {
    color: #878787;
}

.bg-inactive {
    background-color: #f4f4f4;
}

.text-grey {
    color: #666666;
}

.bg-campaign {
    background-color: #f4f4f4;
}

.bg-highlight {
    background-color: #cccbeb;
}

.rounded-12 {
    border-radius: 13px !important
}

.underline-selected {
    border-bottom: 2px solid blue;
}

.text-blue2 {
    color: #5761ca;
}

.bg-status {
    background-color: #ecfaec;
}

.bg-selected {
    background-color: #f3f3fb;
}

.border-5-lt-blue {
    border-left: 5px solid blue
}

.border-grey {
    border-color: #e0e0e0 !important;
}

.cadence-background {
    background-color: #408EF1;
}

.w-70 {
    width: 70%;
}

.cursor {
    cursor: pointer;
}

.border-0 {
    border-radius: 0 !important
}

.text-status {
    color: #72bb6f;
}

.text-inactive {
    color: #878787;
}

.bg-inactive {
    background-color: #f4f4f4;
}

.text-grey {
    color: #666666;
}

.bg-campaign {
    background-color: #f4f4f4;
}

.bg-highlight {
    background-color: #cccbeb;
}

.rounded-12 {
    border-radius: 13px !important
}

.underline-selected {
    border-bottom: 2px solid blue;
}

.text-blue2 {
    color: #5761ca;
}

.bg-status {
    background-color: #ecfaec;
}

.bg-selected {
    background-color: #f3f3fb;
}

.border-5-lt-blue {
    border-left: 5px solid blue
}

.mat-button-base {
    .mat-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        mat-icon {
            flex: 0 0 auto;
        }
    }
}

.rounded-25 {
    border-radius: 25px !important;
}

.mat-button-base.scale {
    .mat-button-focus-overlay {
        transform: scale(0);
        transition-timing-function: $ease-in-out-curve-function;
        transition-property: transform, opacity;
    }
    &:hover .mat-button-focus-overlay,
    &.mat-stroked-button:hover .mat-button-focus-overlay {
        transform: scale(1);
        opacity: .04;
    }
}

.mat-button-base.text-base {
    transition: $ease-in-out-curve-function all 0.2s;
    color: mat-color($foreground, secondary-text);
    &:hover {
        color: mat-color($foreground, text);
    }
}

.mat-primary:not(.mat-form-field) .text-base {
    color: inherit;
    &:hover {
        color: inherit;
    }
}

.mat-menu-item {
    display: flex !important;
    align-items: center;
}

mat-icon {
    &.mat-18 {
        width: 18px;
        height: 18px;
    }
    &.mat-24 {
        width: 24px;
        height: 24px;
    }
    &.mat-36 {
        width: 36px;
        height: 36px;
    }
    &.mat-48 {
        width: 48px;
        height: 48px;
    }
}

mat-horizontal-stepper {
    &.click-no .mat-horizontal-stepper-header {
        pointer-events: none;
    }
    &.space-container .mat-horizontal-content-container {
        padding-top: map-get($spacers, 4);
    }
}

.mat-form-field.no-padding {
    &.no-padding {
        .mat-form-field-wrapper {
            padding-bottom: 0;
        }
        .mat-form-field-underline {
            bottom: 0;
        }
    }
}

.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
    @include theme-elevation(4);
}

.mat-autocomplete-panel.search {
    box-shadow: 0px 2px 4px -1px rgba(63, 81, 181, 0.2), 0px 4px 5px 0px rgba(63, 81, 181, 0.14), inset 0px 1px 0px rgba(63, 81, 181, 0.12) !important;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.page mat-sidenav {
    .mat-expansion-panel-body {
        padding: 0 0 16px !important;
        // box-shadow: inset 4px 0 0 mat-color($mat-primary, text);
        .mat-list-item-content {
            transition: padding $mat-elevation-transition-duration $mat-elevation-transition-timing-function;
            padding: 0 calc(16px + 24px) !important;
        }
        .dash-sidebar-item {
            box-shadow: inset 4px 0 0 mat-color($mat-primary, text);
        }
    }
    &:not(.is-open) {
        .mat-expansion-panel-body .mat-list-item-content {
            padding: 0 16px !important;
        }
    }
}

mat-sidenav-container mat-sidenav .mat-drawer-inner-container {
    display: flex;
    flex-direction: column;
    .mat-list-item {
        .mat-list-item-ripple.mat-ripple {
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
        }
        &.primary-item {
            @include mat-elevation-transition();
            @include theme-elevation(4);
            background: mat-color($background, 'card');
            border-radius: 50px;
            max-width: fit-content;
            height: 56px !important;
            width: calc(100% - 8px);
            align-self: start;
            margin-top: calc(1rem - 8px);
            margin-bottom: calc(0.5rem + 8px);
            margin-left: 0.25rem;
            &:hover {
                @include theme-elevation(8);
            }
            .mat-list-item-content {
                padding-left: 10px !important;
            }
            .mat-icon {
                width: 36px;
                height: 36px;
                padding-left: 0;
                margin-right: 10px;
            }
            .mat-icon,
            span {
                color: mat-color($foreground, text) !important;
            }
        }
        &:not(.primary-item) .mat-list-item-content {
            padding: 0 23px !important;
        }
    }
}


/* Material Cards */

.mat-card-header-text {
    width: 100%;
}


/* Material Tabs */

.mat-tab-nav-bar {
    .mat-tab-links {
        .mat-tab-link {
            color: mat-color($foreground, secondary-text);
            font-weight: 500;
            letter-spacing: .08em;
            text-transform: uppercase;
            opacity: 1;
            .mat-ripple-element {
                background: rgba(mat-color($mat-primary, default), 0.1);
            }
            &:hover {
                background: rgba(mat-color($mat-primary, default), 0.04);
            }
            &:focus {
                transition-duration: 75ms;
                background: rgba(mat-color($mat-primary, default), 0.12);
            }
            &.mat-tab-label-active {
                color: mat-color($mat-primary, default);
            }
        }
    }
}

//.mat-tab-header,
//.mat-tab-header .mat-tab-link-container {
//  overflow: visible !important;
//}
//.tabs-fab-wrapper {
//  position: relative;
//  margin-left: auto;
//
//  button {
//    position: absolute;
//    top: calc(50% - 4px);
//    right: 0;
//  }
//}

/* Material SubHeader */

.parent-subheader {
    .mat-subheader {
        display: flex;
        padding: 0 16px;
        height: 48px;
        align-items: center;
        color: mat-color($foreground, secondary-text);
        text-transform: uppercase;
        font-size: mat-font-size($config, caption);
        letter-spacing: 0.8px;
    }
    .subheader-child {
        padding-left: 32px;
    }
}


/* Material light theme */

.mat-action-list .mat-list-item {
    &:hover,
    &:focus {
        background: transparent;
    }
}

.mat-card.mat-theme-elevation,
.mat-theme-elevation {
    @include mat-elevation(6,
    mat-color($mat-primary, default),
    0.4);
}

.mat-checkbox {
    width: 100%;
    height: 100%;
    .mat-checkbox-layout {
        width: 100%;
        height: 100%;
        justify-content: center;
    }
}

button:focus {
    outline: none;
}

.mat-checkbox-layout {
    margin: 0;
    display: flex !important;
}

.checkbox-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    height: max-content;
    flex: 0 0 auto;
    &.icon-only {
        width: 40px;
        height: 40px;
    }
}

mat-toolbar {
    .mat-form-field {
        font-size: 14px;
        .mat-form-field-wrapper {
            padding-bottom: 0 !important;
            .mat-form-field-underline {
                bottom: 0;
            }
        }
    }
    &.page-title {
        position: relative;
        z-index: 1;
        height: 104px;
        h1 {
            margin-left: 33px;
            font-size: 28px;
            letter-spacing: 1.25px;
        }
    }
    &.mat-primary {
        mat-paginator {
            color: mat-color($mat-primary, default-contrast);
            background-color: mat-color($mat-primary, default);
            button {
                color: mat-color($mat-primary, default-contrast);
                background-color: mat-color($mat-primary, default);
            }
        }
    }
    &.mat-accent {
        mat-paginator {
            color: mat-color($mat-primary, default-contrast);
            background-color: mat-color($accent, default);
            button {
                color: mat-color($accent, default-contrast);
                background-color: mat-color($accent, default);
            }
        }
    }
}

.mat-menu-panel.date-range-picker {
    max-width: unset;
    min-width: auto;
    sat-calendar {
        width: 296px;
        height: 354px;
    }
}


/* Inbox */

.inbox .conversation {
    .mat-checkbox-frame {
        border-color: rgba(mat-color($foreground, text), 0.24);
    }
    &:hover .mat-checkbox-frame {
        border-color: mat-color($foreground, secondary-text);
    }
    &.active .mat-checkbox-frame {
        border-color: inherit;
    }
}

.scrollbar {
    height: 60vh;
    overflow-y: scroll;
    }
/* Global */

.mat-list-base .mat-list-item.info-item,
.info-item {
    min-height: 48px;
    height: auto;
    .mat-list-item-content {
        min-height: inherit;
    }
    .label {
        color: mat-color($foreground, secondary-text);
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 0.8px;
        font-weight: mat-font-weight($config, body-2);
    }
    .text {
        font-weight: mat-font-weight($config, body-2);
    }
}

@include media-breakpoint-up(sm) {
    .mat-list-base .mat-list-item.info-item,
    .info-item {
        .label {
            flex: 0 0 156px;
            margin-right: 1rem;
        }
        .text {
            flex: 1 1 auto;
            min-width: 0;
            padding-right: 24px;
        }
    }
}

@include media-breakpoint-down(xs) {
    .mat-list-base .mat-list-item.info-item,
    .info-item {
        .mat-content,
        .mat-list-item-content {
            flex-direction: column !important;
            align-items: start;
        }
        &:not(:last-of-type) {
            margin-bottom: 8px;
        }
        .text {
            width: 100%;
        }
    }
}

html {
    height: 100%;
}

body {
    margin: 0;
    height: 100%;
}

.c-pointer {
    cursor: pointer;
}

[hidden] {
    display: none !important;
}

.dropdown-item:focus,
.card:focus {
    outline: none;
}

.btn-link:hover,
.btn-link:focus {
    text-decoration: none;
}

.dropdown-menu {
    box-shadow: 0 .5rem 1rem rgba($primary, .15);
    .dropdown-header {
        font-size: $font-size-base * .875;
    }
    .dropdown-item {
        font-weight: 500;
        min-height: 48px;
        fa-icon {
            margin-right: calc($dropdown-item-padding-x / 2);
        }
    }
    &.selectable .dropdown-item:hover,
    &.selectable .dropdown-item:focus,
    &.selectable .dropdown-item.active {
        background-color: rgba($primary, 0.15);
        color: $primary;
    }
}

.nav-tabs {
    .nav-link {
        color: $secondary;
        transition: $transition-base;
        font-weight: 500;
        border-radius: 0;
        border: none;
        &:focus {
            box-shadow: none;
        }
        &:hover:not(.active) {
            color: $dark;
        }
        &.active {
            box-shadow: inset 0 -2px 0 $primary;
        }
    }
}

.page-header {
    background: $white;
    display: flex;
    flex-direction: column;
    .page-title {
        display: block;
        margin: 1.25rem + $nav-link-padding-y $nav-link-padding-x 1.25rem;
        a {
            transition: $transition-base;
            color: $dark;
            text-decoration: none;
            &:hover {
                color: $secondary;
            }
        }
        .back-to-main {
            display: block;
            color: $secondary;
            &:hover {
                color: $primary;
            }
        }
    }
}

.h-100 {
    min-height: 0;
}

.w-100 {
    min-width: 0;
}

pager {
    .pager {
        margin: 0;
        padding: 0;
        display: flex;
        li {
            padding: 0;
            border: none;
            a {
                width: 100%;
                height: 100%;
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                    text-decoration: none;
                }
            }
            &.disabled a:hover {
                cursor: default !important;
            }
        }
    }
}

.btn.circle-fancy {
    position: relative;
    color: $secondary;
    border: none;
    &:not(.disabled) {
        &::after {
            content: '';
            display: block;
            opacity: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
            border-radius: $rounded-pill;
            background-color: rgba($primary, 0.15);
            box-sizing: border-box;
            transform: scale(0);
        }
        &:not(.animation-no)::after {
            transition-duration: .15s !important;
            transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1) !important;
            transition-property: transform, opacity !important;
        }
        &:not(.rounded-circle)::after {
            border-radius: $btn-border-radius;
        }
        &:hover,
        &:focus {
            color: $primary;
            cursor: pointer;
            box-shadow: none;
            &::after {
                background-color: rgba($primary, 0.15);
                border: none;
                opacity: 1;
                transform: scale(1);
            }
        }
        &:active::after {
            box-shadow: none;
            background-color: rgba($primary, 0.25);
        }
    }
    &.rounded-circle {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}